<template>
	<section class="contacts">
		<div class="container">
			<Breadcrumb :pages="pages" class="contacts__crumb" />
			<div class="contacts__header">
				<h1 class="other-title">{{ $t('general.footerContacts') }}</h1>
			</div>
			<div class="contacts__body">
				<div class="contacts__form">
					<form @submit.prevent="sendRequest" v-if="!isFinished">
						<h2>{{ $t('home.leaveRequest') }}</h2>
						<div class="contacts__form-content">
							<or-input
								icon-before="profile"
								class="main-type"
								v-model="formData.name"
								:placeholder="$t('general.name')"
							/>
							<TelInput
								v-model="formData.phone"
								class="contacts__input main-type"
							/>
							<or-button type="submit" class="contacts__form-btn">
								{{ $t('web.callback_submit') }}
							</or-button>
						</div>
					</form>
					<form v-else @submit.prevent="back" class="modal__body--request">
						<span class="modal__body--request__title">
							{{ $t('modal.titleFinishApplication') }}
						</span>
						<span class="modal__body--request__text">
							{{ $t('modal.titleFinishText1') }}
						</span>
						<!-- <span class="modal__body--request__text" style="color: #c99d56">
							{{ $t('modal.titleFinishText2') }}
						</span> -->
						<or-button
							type="submit"
							class="authform__submit"
							aria-label="Button close"
						>
							{{ $t('general.good') }}
						</or-button>
					</form>
				</div>
				<div class="contacts__map">
					<div id="contactMap" style="height: 100%" />
				</div>
				<div class="contacts__info">
					<p>The Baygate Tower, 18 fl. 05 office</p>
					<a href="tel:+971509156544">+971 50 915 6544</a>
					<a href="mailto:info@owrealty.ae">info@owrealty.ae</a>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { i18n } from '@/i18n'
import Breadcrumb from '@/components/Breadcrumb'
import { setCommonMetaData } from '@/utils/setMetaData'
import TelInput from '@/components/TelInput.vue'
import { createRequest } from '@/api/requestBuilder'
import requestConfigs from '@/api/requestConfigs'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

export default {
	name: 'Contacts',
	components: { Breadcrumb, TelInput },
	setup() {
		setCommonMetaData(`${i18n.global.t('titlePages.contact')} - OWRealty`, {
			description: i18n.global.t('meta.contactDesc'),
			type: 'article',
			title: i18n.global.t('meta.contactTitle'),
			url: window.location.href
		})
	},
	data() {
		return {
			map: null,
			isFinished: false,
			formData: {
				phone: '',
				name: '',
				type: 'developer'
			}
		}
	},
	methods: {
		sendRequest(evt) {
			const clone = Object.assign({}, this.formData)
			clone.phone = `+${clone.phone.split(' ').join('')}`

			createRequest(requestConfigs.POSTSendRequest, {
				jsonPayload: clone
			}).then(() => {
				this.formData.name = ''
				evt.target.reset()

				this.isFinished = true
			})
		},
		back() {
			this.isFinished = false
		}
	},
	mounted() {
		if (!mapboxgl) {
			return
		}

		mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN

		this.map = new mapboxgl.Map({
			container: 'contactMap',
			style: 'mapbox://styles/mapbox/light-v11',
			center: [this.content.lng, this.content.lat],
			zoom: 10
		})

		// Disable rotate
		this.map.dragRotate.disable()
		this.map.touchZoomRotate.disableRotation()

		const marker = document.createElement('div')
		marker.style.backgroundImage = 'url(/images/mapbox-contact.png)'
		marker.style.width = '63px'
		marker.style.height = '63px'
		marker.style.backgroundRepeat = 'no-repeat'
		marker.style.backgroundSize = '100%'
		marker.ariaLabel = ''

		new mapboxgl.Marker(marker)
			.setLngLat([this.content.lng, this.content.lat])
			.addTo(this.map)
	},
	computed: {
		content() {
			return {
				lat: 25.188904,
				lng: 55.263039,
				slug: ''
			}
		},
		pages() {
			return {
				first: {
					title: this.$t('general.home'),
					srcName: 'MainRoute'
				},
				current: this.$t('general.footerContacts')
			}
		}
	}
}
</script>

<style lang="scss">
.modal__body--request {
	display: flex;
	flex-direction: column;
	grid-gap: 15px;
	padding: 20px;
	align-items: center;

	&__title {
		font-size: 14px;
		font-weight: 400;
	}

	&__text {
		font-weight: bold;
		font-size: 24px;
		margin-bottom: -10px;
	}

	& > button {
		width: 160px;
		height: 48px;
		margin-top: 15px !important;
	}
}
</style>

<style lang="scss">
.contacts {
	margin-bottom: 50px;
	&__crumb {
		margin-bottom: 30px;
	}
	&__body {
		display: grid;
		grid-template-columns: 7fr 5fr;
		grid-template-rows: 1fr auto;
		gap: 20px;
	}
	&__form {
		grid-row: 1 / -1;
		padding: 44px 20px 40px;
		border-radius: 1px;
		overflow: hidden;
		background: #f2f2f2;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 26px;
		flex-direction: column;
		h2 {
			font-weight: 700;
			font-size: 28px;
			line-height: 120%;
			text-align: center;
			max-width: 440px;
			margin: 0;
		}
	}
	&__form-content {
		padding: 30px 50px;
		max-width: 400px;
		background: #ffffff;
		border-radius: 4px;
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 17px;

		.tel-input {
			width: 100%;
			max-width: 100%;
		}
		.vti__dropdown-list {
			max-height: 150px;
		}
	}
	&__form-btn.button {
		padding: 12px;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		margin-top: 15px;
	}
	&__map {
		overflow: hidden;
		min-height: 270px;
		border-radius: 4px;
	}
	&__input {
	}
	&__info {
		display: flex;
		flex-direction: column;
		font-weight: 600;
		font-size: 14px;
		line-height: 19px;
		gap: 10px;
		color: #000000;
		p,
		a {
			margin: 0;
			display: grid;
			grid-template-columns: 24px 1fr;
			align-items: center;
			gap: 10px;
			min-height: 32px;
			color: #000000;

			&:before {
				content: '';
				background-repeat: no-repeat;
				width: 32px;
				height: 32px;
			}

			&:first-child::before {
				background-image: url('~@/assets/img/svg/location.svg');
			}

			&:nth-child(2)::before {
				background-image: url('~@/assets/img/svg/phone.svg');
			}

			&:last-child::before {
				background-image: url('~@/assets/img/svg/email.svg');
			}
		}
	}
	@media screen and (max-width: 912px) {
		&__body {
			grid-template-columns: 1fr;
		}
		&__form {
			grid-row: unset;
		}
		&__map {
			height: 270px;
		}
	}
	@media screen and (max-width: 600px) {
		&__form {
			overflow: unset;
		}
	}
	@media screen and (max-width: 479px) {
		&__body.container {
			padding: 0;
			gap: 0;
		}
		&__form {
			padding: 30px 20px;
			h2 {
				font-size: 18px;
				line-height: 25px;
				max-width: 300px;
			}
		}
		&__form-content {
			padding: 30px 20px;
		}
		&__map {
			margin-bottom: 20px;
		}
		&__info {
			padding: 0 20px;
		}
	}
}
</style>
